import React, { useContext, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from 'react-select';
import Modal from "react-modal";
import axios from "axios";
import "./EmailCamp.css";
import { toast } from "react-toastify";
import { AuthContext } from "./context/AuthContext";
const CustomDropdown = ({ children, searchText, ...props }) => {
    const selectedOptions = props.getValue();
  
    const handleOptionClick = (option) => {
      const isSelected = selectedOptions.some((selected) => selected.value === option.value);
  
      if (isSelected) {
        props.setValue(selectedOptions.filter((selected) => selected.value !== option.value));
      } else {
        props.setValue([...selectedOptions, option]);
      }
    };
  
    const isOptionSelected = (option) => {
      return selectedOptions.some((selected) => selected.value === option.value);
    };
  
    const filteredOptions = props.options.filter((option) =>
      option.label.toLowerCase().includes(searchText.toLowerCase())
    );
  
    return (
      <div className="custom-dropdown" style={{     maxHeight:"250px",
      minHeight:"250px",
      overflowY:"auto",
      background:"#fff",
      boxShadow:"none"}}>
        {/* Show selected options with radio buttons */}
        {filteredOptions.map((option) => (
          <div onClick={() => handleOptionClick(option)} key={option.value} className={`custom-option ${isOptionSelected(option)?"selected":""}`} style={{backgroundColor:isOptionSelected(option)?"rgb(0 70 134 / 8%)":""}}>
              <label htmlFor={option.value}>{option.label}</label>
              <div  className="circle"></div>
            {/* <input
              type="radio"
              id={option.value}
              name={option.label}
              checked={isOptionSelected(option)}
              onChange={() => handleOptionClick(option)}
            /> */}
          
          </div>
        ))}
  
        {/* Show available options */}
        {React.cloneElement(children, { ...props })}
      </div>
    );
  };
const EmailCampaign=()=>{
    const [modalIsOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const[error,setError]=useState("")
    const selectRef = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [subject, setSubject] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const[contactOptions,setContactoptions]=useState(false)
    useEffect(() => {
        getContacts()
      }, []);
    const {auth,setAuth,tasklength,setTasklength} =useContext(AuthContext)
    const headers = {
        Authorization: auth.token,
      };
      const url = process.env.REACT_APP_API_URL;
      const PlaceholderWithIcon = (props) => (
        <div style={{ display: 'flex', alignItems: 'center',justifyContent:"space-between" }}>
         {/* Adjust icon and styling */}
          <span>{props.children}</span>  <img style={{width:"17px",filter:"brightness(4.5)"}} src="/search.svg" /> 
        </div>
      );
    // Sample list of contacts, replace it with your actual contacts data
    const getContacts = async () => {
        try {
          const response = await axios.get(`${url}api/get/contacts/email`, { headers });
          const contactsWithoutParentId = response.data.filter((contact) => contact.parentId === null);
          const nonvendorcontacts = contactsWithoutParentId.filter((contact) => contact.isVendor === false);
          const contactsWithoutParentIdandlead = nonvendorcontacts.filter((contact) => contact.isLead === false );
          // Set the filtered contacts in the state
    
        
          const realtorOptions =contactsWithoutParentIdandlead.map((realtor) => ({
            value:realtor.id ,
            label: realtor.firstname,
          }));
          setContactoptions(realtorOptions)
    
        } catch (error) {
          console.log(error)
          // localStorage.removeItem('token');
          // setAuth(null);
          // navigate('/');
        }
    
      };
      const colourStyles = {
        valueContainer:styles=>({...styles,overflowX:"auto",flex:"unset",flexWrap:"no-wrap",width:selectedContacts?.length>0?"354px":"100%",padding:"2px 0",
        '&::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
          'border-radius': '10px',
          'background-color': 'rgb(0 70 134)',
        },
        '&::-webkit-scrollbar': {
          'height': '8px',
          'background-color': 'rgb(0 70 134)',
        },
        '&::-webkit-scrollbar-thumb': {
          'border-radius': '10px',
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
          'background-color': '#373a47',
        },
      
      }),
        menu:(styles)=>({
          ...styles,
          maxHeight:"242px",
          minHeight:"242px",
          overflowY:"auto",
          boxShadow:"none",
     
      
        }),
        menuList:styles=>({...styles,overflowY:"none",display:"none"}),
        multiValue:styles=>({...styles,minWidth:"unset"}),
        input: styles =>({...styles,color:"#fff"}),
        placeholder: styles =>({...styles,color:"#fff"}),
        control: styles => ({ ...styles, boxShadow:"unset",borderColor:"unset",minHeight:"0",
        border:"none",borderRadius:"0" ,background:"linear-gradient(240deg, rgba(0,72,137,1) 0%, rgba(0,7,44,1) 100%)",
      padding:"10px 5px"
      }),
    
       
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
         
          return {
            ...styles,
          
         
          };
        },
      
      }; 
  
    const handleContactChange = (selectedOptions) => {
      setSelectedContacts(selectedOptions);
    };
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          overflow:"unset",
          padding: '0px',
          transform: "translate(-50%, -50%)",
          background: "rgb(255 255 255)",
        },
        overlay:{
          backgroundColor: "rgb(0 0 0 / 34%)",
          zIndex:"9"
        }
      };
      const sendRefferal=async()=>{
        try{
        const response = await axios.post(`${url}api/contacts/email`,
         {selectedContacts: selectedContacts.map(option => option.value),emailContent:content.emailContent,subject:subject}, {
          headers,
        });
    
        if (response.status === 200) {
          toast.success("Email Sent successfully", {
            autoClose: 3000,
            position: toast.POSITION.TOP_RIGHT,
          });
          setSelectedContacts()
          closeModal()
      
        }
      }
      catch(err){
        toast.error("Email server is bussy try after sometime", {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
        });
        setSelectedContacts()
        closeModal()
    
      }
      }
      const closeModal = () => {
        setIsOpen(false);
      };
    const [content, setContent] = useState({
        emailContent: "",
       // children: [],
      });
   return (
    <div className="add_property_btn">

       <div className="inner-pages-top">

     
<h3> Email Campaigns</h3>

<div className="search-group">

 <input type="text"
 value={searchQuery}
 onChange={(e) => setSearchQuery(e.target.value)}
 placeholder="Search here"/>
 <img src="/search.svg" />
</div>


      {/* Rest of your component remains the same... */}
      </div>
       <div className="camp-gap">
       <input type="text"
 value={subject}
 onChange={(e) => setSubject(e.target.value)}
 placeholder="Subject"/>
       <CKEditor
  editor={ClassicEditor}
  data={content.emailContent}
  onChange={(event, editor) => {
    const data = editor.getData();
    setContent({ ...content, emailContent: data });
  }}
  config={{
    toolbar: ["heading", "|", "bold", "italic", "link", "|", "bulletedList", "numberedList", "|", "undo", "redo"],
  }}
  className="custom-ckeditor"
  style={{ width: "100%"}}
/>

  <div className="icon-dashboard share-ref-top-wrp">
        <button onClick={()=> setIsOpen(true)}>
        <p>Share</p>
        

        </button>
      </div>
      </div>
  <Modal
  isOpen={modalIsOpen}
             onRequestClose={closeModal}
             style={customStyles}
             >

<div className="modal-roles-add convert-lead-pop-up-content pop-up-content-category" >
                    <img className="close-modal-share" onClick={closeModal} src="/plus.svg"/>
          <form onSubmit={(e)=>{
 e.preventDefault()
  sendRefferal()
          }}>
                        <h3 className="heading-category">Select Contact(s) </h3>
            {error&&<p className="error-category">{error}</p>}
            <Select
             placeholder={<PlaceholderWithIcon>Select Contacts...</PlaceholderWithIcon>}
             ref={selectRef}
  value={selectedContacts}
  menuIsOpen={true}
  onChange={(selectedOptions) => {
    setSelectedContacts(selectedOptions);

    // You can also extract the values into an array if needed

  }}
  onInputChange={(input) => 
    setSearchText(input)}
  options={contactOptions}
  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null,Menu: (props) => <CustomDropdown searchText={searchText} {...props} /> }}
  styles={colourStyles}
  className="select-new"

  isMulti // This is what enables multiple selections
/>
          <div className="modal-convert-btns">
                      <button type="submit">Share</button>
          </div>

          </form>
          </div>
  </Modal>

    </div>
   )
}
export default EmailCampaign;